import { instance } from '@/utils/http.js'

// 获取题目显示类型
export const getShowList = function () {
  return instance({
    url: '/api/v2/paper/topic_lst',
    method: 'get'
  })
}


<template>
  <div>
    <Table ref="TablesRef"
           :tableData="tableData" />
  </div>
</template>

<script>
import { getShowList } from '@/api/topicShowType.js'
export default {
  data () {
    return {
      tableColumns: [
        { prop: 'topic', align: 'center', label: '题目显示类型编号' },
        { prop: 'topic_name', align: 'center', label: '题目显示类型名称' },

      ],
      tableData: [],
    }
  },
  methods: {
    setTablesColums () {

      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    async initData () {

      const { data } = await getShowList()
      this.tableData = data
    }
  },
  mounted () {
    this.setTablesColums()
    this.initData()
  }
}
</script>

<style>
</style>